import React, { useEffect, useState } from "react";
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
    uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../firebase";
import {
    collection,
    addDoc,
    getDocs,
    deleteDoc,
    doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import IsLogged from "./IsLogged";
import EliminaModal from "./Archivio/Elimina";
import ModificaModal from "./Archivio/Modifica";
import { MdDelete } from "react-icons/md";

function Archivio() {
    IsLogged();
    if (!IsLogged()) {
        window.location.href = "/admin";
    }

    const [file, setFile] = useState(null);
    const [Data, setData] = useState([]);
    const [eventi, setEventi] = useState([]);
    const [ID, setID] = useState([]);
    const [canc, setCanc] = useState(null);
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [eliminaEvento, setEliminaEvento] = useState(false);
    const [modificaEvento, setModificaEvento] = useState(false);
    const [eventoScelto, setEventoScelto] = useState("");
    const [descrizione, setDescrizione] = useState("");

    const upload = (e) => {
        e.preventDefault();
        if (!file.name) {
            return;
        }
        if (!eventoScelto) {
            return;
        }
        const name = Date.now() + file.name;
        const storageRef = ref(storage, "images/archivio/" + name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",

            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                        console.log("Upload is unknown");
                        break;
                }
            },
            (error) => {
                switch (error.code) {
                    case "storage/unauthorized":
                        break;
                    case "storage/canceled":
                        break;

                    case "storage/unknown":
                        break;
                    default:
                        console.log("General Error");
                        break;
                }
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                        console.log("File available at", downloadURL);
                        await addDoc(collection(db, "archivio"), {
                            url: downloadURL,
                            name: file.name,
                            descr: descrizione,
                            type: eventoScelto,
                            date: date,
                        });
                        setFile(null);
                        setDate("");
                        setEventoScelto("");
                        handleLoad();
                    }
                );
            }
        );
    };

    const handleLoad = async () => {
        const querySnapshot = await getDocs(collection(db, "archivio"));
        const docs = querySnapshot.docs;
        setID(docs.map((doc) => doc.id));
        setData(docs.map((doc) => ({ id: doc.id, ...doc.data() })));

        const querySnapshot2 = await getDocs(collection(db, "eventi"));
        const docs2 = querySnapshot2.docs;
        setEventi(docs2.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    const elimina = async (i) => {
        await deleteDoc(doc(db, "images", ID[i]));
        handleLoad();
        setCanc(-1);
    };

    useEffect(() => {
        handleLoad();
    }, []);
    useEffect(() => {
        handleLoad();
    }, [eliminaEvento, modificaEvento]);

    const addEvento = async () => {
        if (eventi.filter((item) => item.title === title).length > 0) {
            return;
        }
        await addDoc(collection(db, "eventi"), {
            title: title,
            date: Date.now(),
        });
        setTitle("");
        handleLoad();
    };

    return (
        <div className="mt-10">
            <div className="p-10 pl-20">
                <button
                    onClick={() => window.history.back()}
                    type="button"
                    class="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                >
                    Torna indietro
                </button>
            </div>
            <div className="flex flex-col w-full min-h-[80vh] space-y-20  items-center">
                <div className="flex justify-evenly w-full">
                    <div className="flex flex-col space-y-5">
                        <label
                            class="block mb-2 text-4xl font-medium text-gray-900 "
                            for="file_input"
                        >
                            Carica Foto Archivio
                        </label>
                        <input
                            onChange={(e) => setFile(e.target.files[0])}
                            className="block w-full text-md text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 "
                            id="file_input"
                            type="file"
                        ></input>
                        <div className="flex flex-col w-full">
                            <label htmlFor="">Descrizione:</label>
                            <textarea
                                onChange={(e) => {
                                    setDescrizione(e.currentTarget.value);
                                }}
                                value={descrizione}
                                name="descr"
                                id="descr"
                                className="border border-black w-full"
                            ></textarea>
                        </div>

                        <div className="flex justify-stretch w-full">
                            <div className="flex flex-col">
                                <label htmlFor="">Data:</label>
                                <input
                                    onChange={(e) => {
                                        setDate(e.currentTarget.value);
                                    }}
                                    value={date}
                                    type="date"
                                    class="text-black w-36  border-2 border-blue-600 font-medium rounded-lg text-sm px-2 py-1.5 mr-2 mb-2  focus:outline-none focus:ring-blue-800"
                                />
                            </div>
                            <div className="flex mr-2 px-3 flex-col ">
                                Evento:
                                <select
                                    onChange={(e) =>
                                        setEventoScelto(e.currentTarget.value)
                                    }
                                    name=""
                                    className="text-black w-36  border-2 border-blue-600 font-medium rounded-lg text-sm px-2 py-1.5 mr-2 mb-2  focus:outline-none focus:ring-blue-800"
                                    id=""
                                >
                                    <option value=""></option>
                                    {eventi.map((item, i) => {
                                        return (
                                            <option key={i} value={item.id}>
                                                {item.title}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <button
                                onClick={(e) => upload(e)}
                                type="button"
                                class="text-white w-28 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  mb-2 dark:bg-blue-600 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800"
                            >
                                Carica su Archivio
                            </button>
                        </div>
                        <div className="w-44">
                            {file && (
                                <img src={URL.createObjectURL(file)} alt="" />
                            )}
                        </div>
                    </div>
                    <div className="">
                        <h2 className="text-5xl text-center mb-10">
                            Gestisci Eventi
                        </h2>
                        <div className=" flex space-x-4">
                            <input
                                onChange={(e) =>
                                    setTitle(e.currentTarget.value)
                                }
                                value={title}
                                type="text"
                                className="w-72 border-2 border-blue-500"
                            />
                            <button
                                className="bg-blue-500 text-white px-5 py-2 rounded-lg"
                                onClick={() => {
                                    title && addEvento();
                                }}
                            >
                                Aggiungi
                            </button>
                        </div>
                        <div className="mt-5 h-44 overflow-y-scroll border items-center flex flex-col border-blue-500">
                            {eventi.map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="grid grid-cols-3 w-full pl-5 border-b border-blue-500"
                                    >
                                        <p>{item.title}</p>
                                        <button
                                            className="hover:bg-blue-400"
                                            onClick={() =>
                                                setModificaEvento(item.id)
                                            }
                                        >
                                            Modifica
                                        </button>
                                        <button
                                            className="hover:bg-red-400"
                                            onClick={() =>
                                                setEliminaEvento(item.id)
                                            }
                                        >
                                            Elimina
                                        </button>
                                        {item.id === eliminaEvento && (
                                            <EliminaModal
                                                isOpen={eliminaEvento}
                                                setIsOpen={setEliminaEvento}
                                                item={item}
                                            />
                                        )}
                                        {item.id === modificaEvento && (
                                            <ModificaModal
                                                isOpen={modificaEvento}
                                                setIsOpen={setModificaEvento}
                                                item={item}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center space-y-2">
                    {eventi.map((item, i) => {
                        return (
                            <div key={i}>
                                <p>{item.title}</p>
                                <div className="flex flex-wrap ">
                                    {Data.map((foto, i) => {
                                        if (item.id === foto.type) {
                                            return (
                                                <div
                                                    key={i}
                                                    className="relative m-4 w-44 "
                                                >
                                                    <img
                                                        className=" bg-gray-200 aspect-square object-contain"
                                                        src={foto.url}
                                                        alt=""
                                                    />
                                                    <div className="flex items-center justify-between">
                                                        <p className="text-black">
                                                            {foto.date}
                                                        </p>
                                                        <div
                                                            onClick={() =>
                                                                setCanc(foto.id)
                                                            }
                                                            className=" text-3xl font-bold text-red-500 cursor-pointer"
                                                        >
                                                            <MdDelete />
                                                        </div>
                                                    </div>
													<div className="">
														<p>{foto.descr && foto.descr}</p>
													</div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {canc !== null && (
                    <div>
                        <div
                            id="popup-modal"
                            tabindex="-1"
                            class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto max-h-full"
                        >
                            <div class="relative w-full max-w-md max-h-full">
                                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <button
                                        onClick={() => setCanc(null)}
                                        type="button"
                                        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                        data-modal-hide="popup-modal"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            class="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                    <div class="p-6 text-center flex flex-col items-center w-full">
                                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                            Sicuro di cancellare questa foto?
                                        </h3>
                                        {Data.map((item, i) => {
                                            if (item.id === canc) {
                                                return (
                                                    <img
                                                        src={item.url}
                                                        alt=""
                                                    />
                                                );
                                            }
                                        })}
                                        <div className="mt-5">
                                            <button
                                                onClick={() => {
                                                    deleteDoc(
                                                        doc(
                                                            db,
                                                            "archivio",
                                                            canc
                                                        )
                                                    );
                                                    setCanc(null);
                                                    handleLoad();
                                                }}
                                                data-modal-hide="popup-modal"
                                                type="button"
                                                class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                            >
                                                Si, sono sicuro
                                            </button>
                                            <button
                                                onClick={() => setCanc(null)}
                                                data-modal-hide="popup-modal"
                                                type="button"
                                                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                            >
                                                No, annulla
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Archivio;
