import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import LazyLoad from "react-lazyload";

function Griglieria({ title, foto, tab }) {
    const [isOpen, setIsOpen] = useState(false);
    const [indice, setIndice] = useState(-1);
    console.log(foto, tab);

    return (
        <div>
            <h1 className="text-center text-3xl font-semibold uppercase mb-7">
                {title}
            </h1>
            <div className="grid grid-cols-3 md:grid-cols-6 place-items-center gap-1 md:gap-5 px-5">
                {foto.reduce((acc, item, index) => {
                    if (tab.toLowerCase() === item.type) {
                        const currentIndex = foto.filter(photo => photo.type === tab.toLowerCase()).indexOf(item);
                        acc.push(
                            <div className="h-full" key={index}>
                                <LazyLoad height={200} offset={100} once>
                                    <img
                                        onClick={() => {
                                            setIndice(currentIndex);
                                            setIsOpen(true);
                                        }}
                                        className="cursor-pointer hover:scale-95 transition-all duration-150 h-full object-cover"
                                        src={item.url}
                                        alt=""
                                    />
                                </LazyLoad>
                              
                            </div>
                        );
                    }
                    return acc;
                }, [])}
                <SpringModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    foto={foto.filter(
                        (item) => item.type === tab.toLowerCase()
                    )}
                    indice={indice}
                    setIndice={setIndice}
                />
            </div>
        </div>
    );
}

export default Griglieria;

const SpringModal = ({ isOpen, setIsOpen, foto, indice, setIndice }) => {
    const slide = (segno) => {
        if (segno === "next") {
            if (indice === foto.length - 1) {
                setIndice(0);
            } else {
                setIndice(indice + 1);
            }
        } else {
            if (indice === 0) {
                setIndice(foto.length - 1);
            } else {
                setIndice(indice - 1);
            }
        }
    };
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer select-none"
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        onClick={(e) => e.stopPropagation()}
                        className="flex flex-col items-center justify-center bg-white p-1 md:p-6 rounded-lg w-full md:h-[80vh] h-[60vh] shadow-xl cursor-default relative overflow-hidden"
                    >
                        <IoCloseOutline
                            onClick={() => {
                                setIsOpen(false);
                            }}
                            className="cursor-pointer place-self-end absolute top-[2%] text-5xl text-white bg-[#1d4ed8] rounded-full hover:scale-90 transition-all duration-300"
                        />{" "}
                        <div className="h-full">
                            <CustomPrevArrow click={() => slide("prev")} />
                            <img
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                                className="md:max-w-6xl  h-[100%] object-contain"
                                src={foto[indice].url}
                                alt=""
                            />
                              <p>{foto[indice].descr && foto[indice].descr}</p>
                            <CustomNextArrow click={() => slide("next")} />
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
const CustomPrevArrow = ({ click }) => {
    return (
        <svg
            onClick={() => click()}
            className="absolute left-1/2 -translate-x-[200%] z-20 bottom-0 -translate-y-[95%] rotate-180 cursor-pointer md:hover:scale-90 transition-all duration-300"
            height="40px"
            width="40px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path
                            d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
                            fill="rgb(29 78 216)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
const CustomNextArrow = ({ click }) => {
    return (
        <svg
            onClick={() => click()}
            className="absolute right-1/2 translate-x-[200%] -translate-y-[95%] bottom-0 cursor-pointer md:hover:scale-90 transition-all duration-300"
            height="40px"
            width="40px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path
                            fill="rgb(29 78 216)"
                            d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.616,256-256S397.385,0,256,0z M346.899,280.959 l-85.594,85.594c-13.783,13.784-36.132,13.784-49.917,0c-13.784-13.784-13.784-36.133,0-49.917L272.023,256l-60.635-60.635 c-13.784-13.784-13.784-36.133,0-49.917s36.134-13.784,49.917,0l85.594,85.594C360.683,244.825,360.683,267.175,346.899,280.959z"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};
